.iiz {
  border-radius: 10px;
  box-shadow: rgba(84, 94, 165, 0.1) 0px 3px 3px -2px, rgba(84, 94, 165, 0.1) 0px 3px 4px 0px, rgba(84, 94, 165, 0.1) 0px 1px 8px 0px;
}

.MuiBottomNavigationAction-root {
  max-width: 200px !important;
  box-shadow: rgba(84, 94, 165, 0.1) 0px 3px 3px -2px, rgba(84, 94, 165, 0.1) 0px 3px 4px 0px, rgba(84, 94, 165, 0.1) 0px 1px 8px 0px;
}

.bottom-navigation-element.cancel {
  font-weight: bold;
  background: #ffffff;
  border-top-left-radius: 10px;

  &:hover {
    background: rgb(230, 230, 230);
  }
}

.bottom-navigation-element.primary {
  border-top-right-radius: 10px;
}

.switch {

  .MuiFormControlLabel-label {
    text-align: left;
  }
}

.mask-box {
  border-radius: 20px;
  position: relative;
  background: #deedff;
  // box-shadow: 1px 1px 2px rgb(165, 165, 165) inset;
}
.mask-box .mask {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  background: #2484FF;
  position: absolute;
  // box-shadow: 1px 0 2px #2484FF;
  transition: all 0.5s ease;
}
.mask-box .MuiButton-root {
  border-radius: 20px;
  width: 120px;
  height: 40px;
  font-weight: bold;
  transition: all 0.2s 0.1s ease;
}
.mask-box .MuiButton-root:hover {
  opacity: 0.8;
}

.one {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.0s;
  animation: dot 1.3s infinite;
  animation-delay: 0.0s;
}

.two {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.2s;
  animation: dot 1.3s infinite;
  animation-delay: 0.2s;
}

.three {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.3s;
  animation: dot 1.3s infinite;
  animation-delay: 0.3s;
}

@-webkit-keyframes dot {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes dot {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}