.user-clients {

  &:hover {
    cursor: pointer;
    box-shadow: inset 0 24px 64px rgb(100 117 231 / 10%);
  }
}

.search-field {
  border-radius: 10px;

  .MuiFilledInput-root {
    background-color: transparent;
    border-radius: 10px;

    &:hover {
      background-color: transparent;
    }

    &.Mui-hovered,
    &.Mui-focused,
    &.Mui-active {
      background-color: transparent;
    }
  }
}

.select-status {

  .MuiSelect-filled.MuiSelect-filled {
    padding-bottom: 0;
    // margin-top: -3px;
  }

  .MuiSelect-selectMenu {
    display: flex;
    align-items: center;
  }

  .MuiListItemIcon-root {
    min-width: auto;
    margin-right: 10px;
  }
}

.draggable-chip {
  background: #ffffff !important;
  color: rgb(44, 83, 125) !important;
  font-weight: 500;
  font-size: 12px !important;
  border: 1px solid rgb(237, 239, 255) !important;



  @media(max-width: 1400px) {

    .MuiChip-iconSmall {
      margin-left: 2px !important;
      margin-right: 2px !important;
    }
    
    .MuiChip-labelSmall {
      display: none;
    }
  }

  &:hover {
    cursor: pointer !important;
  }

  .MuiChip-deleteIcon {
    color: #e7eaef !important;
  }

  // &.draft {

  //   &:hover {
  //     background: darken(#889dbf , 10%) !important;
  //     color: #fff !important;
  //   }
  // }

  // &.scheduled {

  //   &:hover {
  //     background: darken(rgb(255, 183, 77), 20%) !important;
  //     color: #fff !important;
  //   }
  // }

  // &.sent {

  //   &:hover {
  //     background: darken(rgb(26, 123, 201), 15%) !important;
  //     color: #fff !important;
  //   }
  // }

  // &.payed {

  //   &:hover {
  //     background: darken(rgb(76, 175, 80), 15%) !important;
  //     color: #fff !important;
  //   }
  // }

  // &.overdue {

  //   &:hover {
  //     background: darken(rgb(183, 28, 28), 15%) !important;
  //     color: #fff !important;
  //   }
  // }

  &.active {

    &:hover {
      background: darken(rgb(76, 175, 80), 15%) !important;
      color: #fff !important;
    }
  }

  &.inactive {

    &:hover {
      background: darken(rgb(183, 28, 28), 15%) !important;
      color: #fff !important;
    }
  }

  &.prospect {

    &:hover {
      background: darken(rgb(255, 183, 77), 15%) !important;
      color: #fff !important;
    }
  }

  &:hover {
    background: #ffffff !important;
    // color: #ffffff !important;
    cursor:default;
    // cursor: move; /* fallback if grab cursor is unsupported */
    // cursor: grab;
    // cursor: -moz-grab;
    // cursor: -webkit-grab;

    .MuiChip-deleteIcon {
      // color: rgb(136, 157, 191) !important;
      color: #fff !important;
    }
  }
}

.MuiChip-deleteIcon {
  color: #dbdef5 !important;
}

.MuiDialogTitle-root h2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.accordion {
  margin: 0 !important;
}

.image-name-company {

  // @media(max-width: 1279px) {
  //   flex-direction: column;
  //   padding-bottom: 0 !important;
  // }

  img {
    @media(max-width: 900px) {
      margin-bottom: 5px;
    }
  }
}

.invoice-status {
  flex-wrap: nowrap;
  margin-left: 25px;
  justify-content: flex-start;
  padding-left: 38px !important;

  // @media(max-width: 1460px) {
  //   padding-left: 0 !important;
  // }

  @media(max-width: 1279px) {
    padding-top: 15px;
    margin-left: 0;
  }

  @media(max-width: 1279px) {
    padding-top: 15px !important;

  }

  // @media(min-width: 960px) {
  //   padding-left: 50px;
  // }
}

.invoice-total {

  @media(max-width: 960px) {
    margin-bottom: 5px !important;
  }
}

.invoice-overdue {

  @media(max-width: 960px) {
    margin-top: 5px !important;
  }
}



.invoice-tab  {
  background: rgb(156, 173, 201) !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 5px;
  display: flex;
  justify-content: center;

  button {
    background: transparent !important;
    transition: none;
    font-weight: 500;
    font-size: 15px !important;
    border: 1px solid rgba(156, 173, 201, 0.1);
    border-bottom: none;
    color: white !important;
    padding-right: 5px; 

    .MuiButton-startIcon {
      
      @media(max-width: 600px) {
        margin-right: -4px;
      }
    }

    .MuiButton-label .title{

      @media(max-width: 600px) {
        display: none;
      }
    }

    @media(max-width: 600px) {
      padding-right: 16px;
    }
  }

  .invoice-add-button {
    padding: 5px;
    padding-right: 15px;
    border-top-right-radius: 10px;

    .MuiButtonBase-root {
      color: #2196f3 !important;
      background: white !important;

      &:hover {
        color: darken(#2196f3, 20%) !important;
      }
    }

    @media(max-width: 600px) {
        display: none !important;
    }
  }

  &:not(.active) {
    background: #b4bdcbd8 !important;

    &:hover {
      background-color: #8fa3c5cc !important;
    }

    .MuiButtonBase-root {
      color: white !important;
      background: transparent !important;
    }
  }
}

.chips {
  background: #ffffff;
  min-height: 44px;
  padding: 2px 50px;

  // @media(max-width: 1279px) {
  //   flex-direction: column;
  //   align-items: flex-start !important;
  // }

  // @media(max-width: 959px) {
  //   padding: 2px 15px;
  // }
}

.list-box {
  margin: 25px 50px;

  @media(max-width: 959px) {
    margin: 16px 16px;
  }
}
  
.table-headline {
  border-radius: 6px !important;
  border: 1px solid white !important;

  &:hover {
    cursor: pointer;
    border: 1px solid rgb(237, 239, 255) !important;
  }
}

.invoices-list {
  width: 100%;
  margin-top: 0;

  @media(max-width: 1279px) {
    margin-top: 0;
  }
}

.recurring-info {
  background: transparent !important;

  .recurring-info-icon {
    color: rgb(44, 83, 125) !important;
  }

  &:hover {
    background: rgb(44, 83, 125) !important;

    .recurring-info-icon {
      color: white !important;
    }
  }
}

.send-info {
  // border: 1px solid white;
  background: rgb(237, 239, 255);

  .send-info-icon {
    color: rgb(114 146 183);
  }

  &:hover {
    background: rgb(26, 123, 201);

    .send-info-icon {
      color: white;
    }
  }
}

.payed-info {
  background: rgb(237, 239, 255);

  .payed-info-icon {
    color: rgb(114 146 183);

    &:hover {
      color: white !important;
    }
  }

  &:hover {
    background: rgb(76, 175, 80);
  }
}

.send-info {
  // border: 1px solid white;
  background: rgb(237, 239, 255);

  .send-info-icon {
    color: rgb(114 146 183);

    &:hover {
      color: white !important;
    }
  }

  &:hover {
    background: rgb(26, 123, 201);
  }
}

.download-info {
  background: rgb(237, 239, 255);

  .download-info-icon {
    color: rgb(114 146 183);

    &:hover {
      color: white !important;
    }
  }

  &:hover {
    background: rgb(44, 83, 125);

    .download-info-icon {
      color: white;
    }
  }
}

.main-headline-wrapper {
  @media(max-width: 959px) {
    display: none !important;
  }
}

.add-button {
  margin-left: 10px !important;
  min-width: auto;
  padding: 2px 8px;
  border: 1px solid rgb(44, 83, 125); // Primary color border

  &:hover {
      background-color: #2196f3 !important; // Background color on hover
      color: white !important; // Text color on hover
      border-color: #2196f3 !important; // Border color on hover

      .MuiSvgIcon-root {
          color: white; // Icon color on hover
      }
  }
}

.parameter-container {

  @media(max-width: 959px) {
    width: 100% !important;
  }
}



.search-icon {
  margin-left: 7px;
  padding: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.search-icon.open {
  color: white;
  background-color: rgb(44, 83, 125); /* Replace with theme.palette.primary.main */
  padding: 10px;
}

.search-icon:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Hover background */
  color: #ffffff; /* Hover text color */
}

.search-icon.open:hover {
  color: red;
  background-color: darken(rgb(44, 83, 125), 5%); /* Replace with theme.palette.primary.main */
}

.search {
  width: 500px !important;
}

// prevent background color in input when autosuggest is selected
.search-field input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.search-field input:-webkit-autofill:focus {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
}

.mobile-menu-section {
  background: #ffffff;

  @media(min-width: 960px) {
    display: none;
  }
}

.not-mobile-menu-section {

  @media(max-width: 959px) {
    display: none;
  }
}

.table-section {

  @media(max-width: 1279px) {
    flex-direction: column;
  }
}

.invoice-meta {
  padding: 15px;

  @media(max-width: 1279px) {
    flex-direction: column;
    align-items: flex-start !important;
    padding-left: 16px !important;
    padding-top: 0 !important;
  }

  @media(max-width: 900px) {
    padding-top: 5px !important;
  }
}

.invoice-meta-item-container {

  @media(max-width: 1279px) {
    justify-content: flex-start !important;
  }
}

.invoice-meta-item {

  @media(max-width: 1279px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.recurring-info {
  text-align: center;
}

.client-image {

  @media(max-width: 1279px) {
    margin-left: 10px;
    background: #f7f7f7;
  }
}

.invoice-ref {
  line-height: 1.8;

  @media(max-width: 1279px) {
    display: none !important;
  }
}


.checkbox-invoices {
  @media(max-width: 1279px) {
    display: none !important;
  }
}

.user-clients:hover {

  .checkbox-invoices {
    display: block !important;

    @media(max-width: 1279px) {
      display: none !important;
    }
  }
}

.count-entries {
  margin-left: 50px;
  padding-top: 20px;
  padding-bottom: 0;
  margin-bottom: -20px;
  font-size: 12px;
  color: #889eb5;

  @media(max-width: 959px) {
    display: none !important;
  }
}

.invoice-checkbox {

  @media(max-width: 1460px) {
    display: none;
  }
}

.load-more-small {
  margin-left: 5px;
  border-radius: 6px;
  background: white;

  &:hover {
    cursor: pointer;
    background: rgb(44, 83, 125);
    color: white;
  }
}

.table-lable-invoicenumber-large {

  @media(max-width: 1820px) {
    display: none;
  }
}

.table-lable-invoicenumber-small {

  @media(min-width: 1821px) {
    display: none;
  }
}

.table-lable-invoicedate-large {

  @media(max-width: 1820px) {
    display: none;
  }
}

.table-lable-invoicedate-small {

  @media(min-width: 1821px) {
    display: none;
  }
}

.checkbox-bulkedit {

  @media(max-width: 1460px) {
    display: none;
  }
}

/* Container styles */
.referenz-container {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 1;
  position: relative;
}

/* Content styles */
.referenz-content {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.2s;
  position: relative;
  background-color: #f0f0f0;
}

.referenz-content:hover {
  background-color: #e4e6f7;
}

/* Label styles */
.referenz-label {
  font-weight: bold;
}

/* Info icon styles */
.info-icon {
  margin-right: 5px;
}

/* Delete icon styles */
.delete-icon-reference {
  margin-left: auto;
  margin-left: 5px !important;
  display: none !important; /* Hidden by default */
}

/* Show delete icon on hover */
.referenz-content:hover .delete-icon-reference {
  display: inline-flex !important;
}

