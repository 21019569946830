.react-pdf__Page__canvas,
.react-pdf__Page__svg {
  max-width: 100% !important;
  height: auto !important;
}

.react-pdf__Page__svg svg {
  max-width: 100% !important;
  height: auto !important;
}

// Important to make hover work appropriately
.react-pdf__Page__textContent {
  overflow: hidden;
  max-width: 100%;
}
