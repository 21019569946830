.button--loading {
    padding: 15px !important;
}

.button--xxs {
    display: flex;
    align-items: center;
    padding-right: 5px;
    border-radius: 16px;
    border: 1px solid rgba(211, 211, 211, 0.87);
    
    &:hover {
        cursor: pointer !important;
        border: 1px solid #edefff;
        background: #edefff;
    }
}

.badge-input-wrapper {
    width: 173px;

    &:focus-within {
        background: #dbdef5 !important;
    }
}

.badge-input {
    border: 0 !important;
    outline: 0 !important;
    max-width: 100%;
    background: transparent;
    border: none;

    &:active,
    &:focus,
    &:focus-visible {
        background: #dbdef5 !important;
    }

    &:focus::placeholder {
        color: transparent !important;
      }
}

.sortable-chip {

    &:hover {
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    &:active {
        cursor: -webkit-grabbing;
        cursor:    -moz-grabbing;
        cursor:         grabbing;
    }
}



