$xs: 560px;

$boxShadow1: 0 24px 64px #26214a1a;
$boxShadow2: 0 24px 64px #545ea51a;

$xs: 0;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;
