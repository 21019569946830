* {
  box-sizing: border-box;
}

// html {
//   font-family: 'Source Sans 3';
// }

body {
  margin: 0;
  background-color: #f7fafd !important;
}

main {
  width: 100%;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
  color: inherit;
}

.image-upload-wrapper {
  width: 100%;
  // max-height: 56px;
  border: 1px solid  rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  display: flex;
  align-items: center;

  .form-control {
    width: 100%;
    height: 100%;
  }

  .image-upload {
    width: 100%;
    height: 100%;
  }

  button {
    width: 100%;
    border-radius: 0;
    max-height: 54px;
    border-radius: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.image-upload__preview img:hover {
  cursor: pointer;
}

.image-upload__preview {
  height: 100%;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: rgb(60 100 177 / 10%);
  border-right: 1px solid rgb(165 165 165 / 20%);

  img {
    border-top-left-radius: 1.5px;
    border-bottom-left-radius: 4px;
    max-width: 100%;
    max-height: 100%;
    padding: 5px;
  }
}

.image-upload {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.link {

  &:hover {
    cursor: pointer;
  }
}





.AppWrapper input,
.AppWrapper button {
  all: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.AppWrapper {
  width: 500px;
  height: 400px;
  position: relative;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.AppWrapper .Form {
  animation: fade 200ms ease-out;
}



// https://jsfiddle.net/utdz6pkr/1/
// new primary ? -> background: #666EE8;

// body {
//   background: #E6EBF1;
// }

// .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
// .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
//   border: 1px solid #ffffff !important;
// }

.MuiListItem-root {
  border-left: 5px solid transparent !important;

  &:hover {
    background-color: #f9f9ff !important;
  }

  .MuiTypography-body1 {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
}


nav a.active {
  // color: #2196f3;

  .MuiListItem-root.Mui-selected {
    border-left: 5px solid rgb(44, 83, 125) !important;
    background-color: rgb(237, 239, 255) !important;
  }
  .MuiTypography-body1 {
    font-weight: 9  00 !important;
  }
}

.MuiCollapse-root .MuiListItem-root{
  border-left: 5px solid rgb(219 230 241) !important;
}

.MuiCollapse-root .MuiListItem-root .MuiTypography-body1{
  font-size: 12px !important;
}

.MuiCollapse-root .Mui-selected .MuiTypography-body1 {
  font-weight: 900 !important;
}

.MuiCollapse-root .MuiSvgIcon-colorPrimary {
  color: rgb(44, 83, 125) !important;
  opacity: 0.5;
}

.MuiCollapse-root .Mui-selected .MuiSvgIcon-colorPrimary {
  color: rgb(44, 83, 125) !important;
  opacity: 1;
}



.sticky-nav {
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 149px;
  z-index: 2;
  width: 100%;
  // background: rgb(235, 243, 255) !important;
  background: #ffffff !important;
  width: 100%;
  padding: 12px 0px !important;
  line-height: 24px !important;

  .MuiTypography-body1 {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  @media(max-width: 1279px) {
    display: none !important;
  }
}

.menu-heading-title {
  font-weight: 500;
  margin-right: 5px;

  @media(min-width: 1279px) {
    display: none;
  }
}


// TEXT ANIMATION 1
// Colors
$gradient-color-1: #a01999;
$gradient-color-2: #2196f3;

// Keyframes
@keyframes animationgradienttitle {
  0% {
      background-position: 0 1600px;
  }
  100% {
      background-position: 1600px 0;
  }
}


.text-animation-1 {
  background: -webkit-linear-gradient(left, $gradient-color-1,$gradient-color-2 , $gradient-color-1);
  background-size: 1600px 200px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-duration: 3s;
  animation-name: animationgradienttitle;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}


// TEXT ANIMATION 1
// Colors
$gradient-color-3: #19a051;
$gradient-color-4: #2196f3;

// Keyframes
@keyframes animationgradienttitle2 {
  0% {
      background-position: 0 1600px;
  }
  100% {
      background-position: 1600px 0;
  }
}

.text-animation-2 {
  background: -webkit-linear-gradient(left, $gradient-color-3,$gradient-color-4 , $gradient-color-3);
  background-size: 1600px 200px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-duration: 3s;
  animation-name: animationgradienttitle2;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.MuiToolbar-regular {
  @media(min-width: 600px) {
    min-height: 59px !important;
  }

  @media(max-width: 959px) {
    min-height: 45px !important;
  }
}

.alert-info {
  top: 60px;

  @media(max-width: 959px) {
    top: 45px;
  }
}

.button-save {
  background: rgb(44, 83, 125) !important;
  color: #ffffff !important;

  &:hover {
    background: lighten(rgb(44, 83, 125),  10%) !important;
    color: #ffffff !important;
  }
}

.button-send {
  background: rgb(26, 123, 201) !important;
  color: #ffffff !important;

  &:hover {
    background: lighten(rgb(26, 123, 201),  10%) !important;
    color: #ffffff !important;
  }
}

.MuiBottomNavigation-root  {
  height: 53px !important;

  .MuiButton-label {
    font-size: 13px;
  }
}

.MuiFilledInput-underline:before,
.MuiFilledInput-underline:after {
  display: none !important
}

.MuiFilledInput-root {
  background: #ffffff !important;
  border: 2px solid rgb(240, 243, 255);

  // &:active {
  //   border: 2px solid red !important;
  // }
  // &:focused {
  //   border: 2px solid red !important;
  // }
}

.datepicker {

  .MuiFilledInput-input {
    padding-bottom: 7px !important;
  }

  .MuiFilledInput-adornedEnd {
    border-radius: 3px !important;
  }
}


// .Mui-focused .MuiOutlinedInput-notchedOutline {
//   border: 1px solid red !important;
//   border-color: red !important;
// }

.MuiFilledInput-root {
  border-radius: 4px !important;
}

.MuiFilledInput-root.Mui-focused {
  border: 2px solid #1a7bc9 !important;
  border-color: #1a7bc9 !important;
}

.search-field .MuiFilledInput-root.Mui-focused {
  border: 1px solid #1a7bc9 !important;
  border-color: #1a7bc9 !important;
}

// .MuiInputLabel-filled.MuiInputLabel-shrink {
//   transform: translate(12px, 10px) scale(0.85) !important;
// }

// .MuiInputLabel-outlined.MuiInputLabel-shrink {
//   transform: translate(14px, -6px) scale(0.85) !important;
// }

.MuiButton-outlinedPrimary {
  border: 1px solid rgba(44, 83, 125, 0.1) !important;

  &:hover {
    border: 1px solid rgba(44, 83, 125, 0.1) !important;
    background-color: rgba(44, 83, 125, 0.1) !important;
  }
}

.badge-input--invoicenr  {
  border: 2px solid white !important;

  &:active, &:focus {
    border: 2px solid rgb(44, 83, 125) !important;
  }
}

.headline-section-fullscreendialog-mobile {
  display: flex;
  align-items: center;
  margin-top: -20px;

  @media(min-width: 901px) {
    display: none;
  }
}

.rdw-option-wrapper {

  &:hover {
    background: #dae0eb !important;
    box-shadow: none !important;
  }
}

.rdw-option-active {
  background: #d1dfff !important;
  box-shadow: none !important;
}

/* Global styles for Material-UI Backdrop */
.MuiBackdrop-root {
  backdrop-filter: blur(4px); // Apply blur effect
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent dark background
}