.h-minHeight--100 {
  min-height: 100%;
  min-height: 100vh;
}

.h-gap--5 {
  gap: 5px;
}

.h-gap--10 {
  gap: 10px;
}

.h-gap--15 {
  gap: 15px;
}

.h-gap--20 {
  gap: 20px;
}

.h-gap--25 {
  gap: 25px;
}

.h-gap--30 {
  gap: 30px;
}

.h-gap--35 {
  gap: 35px;
}

.h-gap--40 {
  gap: 40px;
}

.h-gap--45 {
  gap: 45px;
}

.h-gap--50 {
  gap: 50px;
}
@media (max-width: 700px) {
  .h-gap--50 {
    padding: 50px 15px !important;
  }
}

.h-bg--white {
  background: #ffffff;
}

.h-padding--0-16 {
  padding: 0px 16px;
}
@media (min-width: 1280px) {
  .h-padding--0-16 {
    padding: 0px 50px;
  }
}

.h-padding--5-16 {
  padding: 5px 16px;
}
@media (min-width: 1280px) {
  .h-padding--5-16 {
    padding: 5px 16px;
  }
}

.h-padding--25-16 {
  padding: 25px 16px;
}
@media (min-width: 1280px) {
  .h-padding--25-16 {
    padding: 25px 16px;
  }
}

.h-padding--50-16 {
  padding: 25px 16px;
}
@media (min-width: 1280px) {
  .h-padding--50-16 {
    padding: 50px 50px;
  }
}

.h-padding--5-5 {
  padding: 5px 5px;
}
@media (min-width: 1280px) {
  .h-padding--5-5 {
    padding: 5px 5px;
  }
}

.h-borderRadius--10 {
  border-radius: 10px !important;
}

.h-borderRadiusTopRight--10 {
  border-top-right-radius: 10px !important;
}

/* inter-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: url("./../_assets/fonts/inter/inter-v13-latin-100.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: url("./../_assets/fonts/inter/inter-v13-latin-200.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("./../_assets/fonts/inter/inter-v13-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("./../_assets/fonts/inter/inter-v13-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("./../_assets/fonts/inter/inter-v13-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("./../_assets/fonts/inter/inter-v13-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("./../_assets/fonts/inter/inter-v13-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: url("./../_assets/fonts/inter/inter-v13-latin-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: url("./../_assets/fonts/inter/inter-v13-latin-900.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 200;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-200.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: italic;
  font-weight: 200;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-200italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 300;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: italic;
  font-weight: 300;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-300italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: italic;
  font-weight: 400;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: italic;
  font-weight: 500;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-500italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: italic;
  font-weight: 600;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-600italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: italic;
  font-weight: 700;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-700italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 800;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Plus Jakarta Sans";
  font-style: italic;
  font-weight: 800;
  src: url("./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-800italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-100 - latin */
.bg--1 {
  background: #f7fafd;
}

.bg--2 {
  background: linear-gradient(to right top, #051937, #123461, #1d528f, #2473c0, #2196f3);
}

.bg--3 {
  background: linear-gradient(to right top, #051937, #222a67, #573592, #9934b5, #e312ca);
}

.bg--4 {
  background-image: linear-gradient(to right bottom, #051937, #004d7a, #008793, #00bf72, #a8eb12);
}

.bg--5 {
  background-image: linear-gradient(to right bottom, #725e00, #937a00, #b69800, #dab700, #ffd700);
}

.bg--6 {
  background-image: radial-gradient(circle at 60.52% 67.2%, #264697, transparent 41%), radial-gradient(circle at 56.14% 12.23%, #232b69, transparent 85%), radial-gradient(circle at 6.01% 1.34%, #d989b9, transparent 25%), radial-gradient(circle at 75.71% 4.03%, #0a0d66, transparent 34%), radial-gradient(circle at 99.31% 70.7%, #2fd2eb, transparent 75%), radial-gradient(circle at 76.91% 59.14%, #0a0d66, transparent 71%), radial-gradient(circle at 71.07% 83.33%, #0a0d66, transparent 71%), radial-gradient(circle at 15.11% 70.43%, #1556a1, transparent 64%), radial-gradient(circle at 65.49% 37.9%, #3058a5, transparent 100%), radial-gradient(circle at 99.4% 93.55%, #12f7ff, transparent 100%), radial-gradient(circle at 50% 50%, #8f62aa, #8f62aa 100%);
}

.bg--7 {
  background: linear-gradient(90deg, rgb(226, 229, 255) 0%, rgb(255, 255, 255) 100%);
}

.bg--basic {
  background: darkgrey !important;
}

.box-shadow--1 {
  box-shadow: 0 24px 64px rgba(38, 33, 74, 0.1019607843) !important;
}

.box-shadow--2 {
  box-shadow: 0 24px 64px rgba(84, 94, 165, 0.1019607843);
}

@media (max-width: 600px) {
  .tool-menu {
    width: 100%;
    display: flex;
    justify-content: center !important;
  }
}

.grid--xs {
  box-shadow: 0 24px 64px rgba(38, 33, 74, 0.1019607843);
  justify-content: center;
  background: #ffffff;
  border-radius: 10px;
  padding: 50px;
}

.image--logo--presenter {
  max-height: 60px;
  max-width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}

.icon-wrapper--lg {
  display: flex;
  padding: 8px;
  border-radius: 30px;
  margin-bottom: 5px;
}
.icon-wrapper--lg svg {
  color: #ffffff;
}

.icon--with-bg--1 {
  padding: 3px;
  border-radius: 4px;
  background-image: linear-gradient(to right top, rgb(31, 89, 177), rgb(40, 93, 163), rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243));
  color: #ffffff !important;
}

.MuiAlert-icon {
  align-items: center;
}

.MuiAlert-message {
  display: flex;
  align-items: center;
}

.button--loading {
  padding: 15px !important;
}

.button--xxs {
  display: flex;
  align-items: center;
  padding-right: 5px;
  border-radius: 16px;
  border: 1px solid rgba(211, 211, 211, 0.87);
}
.button--xxs:hover {
  cursor: pointer !important;
  border: 1px solid #edefff;
  background: #edefff;
}

.badge-input-wrapper {
  width: 173px;
}
.badge-input-wrapper:focus-within {
  background: #dbdef5 !important;
}

.badge-input {
  border: 0 !important;
  outline: 0 !important;
  max-width: 100%;
  background: transparent;
  border: none;
}
.badge-input:active, .badge-input:focus, .badge-input:focus-visible {
  background: #dbdef5 !important;
}
.badge-input:focus::placeholder {
  color: transparent !important;
}

.sortable-chip:hover {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.sortable-chip:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}