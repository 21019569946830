.invoice-template {
  border-radius: 6px;
  // padding: 20px !important;
  box-shadow: 0 24px 64px #26214a1a;

  &:hover {
    cursor: pointer;
    // box-shadow: 0 24px 64px #26214a1a;
    box-shadow: #26214a40 0px 7px 20px 0px;

  }
}

.bottom-nav {
  position: sticky;
  display: flex;
  justify-content: center;
  gap: 20px;
  background: #fff;
  padding: 15px;
  bottom: 0;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

// .bottom-nav--space-between {
//   position: sticky;
//   display: flex;
//   gap: 5px;
//   justify-content: space-between;
//   background: #fff;
//   padding: 15px;
//   bottom: 0;
//   box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
// }
