.search {
  // padding-left: 40px;
  // padding-right: 40px;

  // @media(max-width: 959px) {
  //   padding-left: 4px;
  //   padding-right: 4px;
  // }

  box-shadow: 0px 0px 1px rgb(0 0 0 / 48%);
  border-radius: 4px;
}