.overflow {
    display: inline-block !important;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.date {
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 6px;

    &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.04);
    }

    @media(max-width: 976px) {
        width: 100%
    }
}

.subpage-header {
    // position: sticky;
    // top: 0;
    z-index: 9;
    width: auto;
    background: #ffffff;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;

    @media(max-width: 959px) {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.entry {

    &:hover {
        cursor: pointer;
        box-shadow: inset 0 24px 64px rgba(100, 117, 231, 0.2);
    }
}

.MuiTable-stickyHeader th {
    top: 122px;
    padding: 8px 16px;

    @media(max-width: 959px) {
     top: 63px;
    }
}

.MuiTableCell-root {
    border-bottom: 1px solid rgb(226, 236, 253) !important;
}

.MuiTableCell-stickyHeader {
    background-color: rgb(235 243 255) !important;
}

.MuiTable-root {
    background: #ffffff;
}

.sums {
    margin-top: 1px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;

    @media(max-width: 1297px) {
        flex-direction: column;
    }
}

.sums-element {

    @media(max-width: 1297px) {
        flex-direction: column;
        margin-bottom: 20px;
    }
}

.sums-element-headline {
    border-right: 2px solid rgba(0, 0, 0, 0.4);

    @media(max-width: 1297px) {
        flex-direction: column;
        border-right: none;
    }
}

