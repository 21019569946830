.alert {

  .MuiAlert-icon {
    display: block;
  }
}

.custom-tooltip {
  background: #ffffff;
  padding: 5px;
  border-radius: 6px;
  box-shadow: 0px 0px 64px rgba(84, 94, 165, 0.3);
}

.subpage-header {
  // position: sticky;
  // top: 0;
  z-index: 9;
  width: auto;
  background: #ffffff;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;

  @media(max-width: 959px) {
      padding-left: 15px;
      padding-right: 15px;

      position: relative !important;
  }
  @media(max-width: 455px) {
    padding-left: 15px;
    padding-right: 15px;

    position: relative !important;
}
}

.feature-box {
  height: 100%;

  .feature-box-inner {
    display: flex;
    align-items: center;
    box-shadow: 0 24px 64px rgba(84, 94, 165, 0.1019607843);
    background: #ffffff;
    border-radius: 10px;
    height: 100%;
    padding: 10px;
  }

  &:hover {
    cursor: pointer;

    .feature-box-inner {
      background: linear-gradient(to right top, rgb(31, 89, 177), rgb(40, 93, 163), rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243));
    }

    .icon--with-bg--1--feature {
      background: linear-gradient(to right top, rgb(57 144 255), rgb(60 159 255), rgb(73, 182, 255), rgb(57, 237, 222), rgb(0, 191, 114)) !important;
    }

    .headline-feature {
      color: #ffffff !important;    /* For Firefox */
    }

    .text-feature {
      color: #ffffff !important;
    }

    .icon--link {
      display: block !important;
    }
  }

  &.no-link {

    .headline-feature {
      background: linear-gradient(to right top, rgb(57 144 255), rgb(60 159 255), rgb(73, 182, 255), rgb(57, 237, 222), rgb(0, 191, 114));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text; /* For Firefox */
      color: transparent;    /* For Firefox */
    }


    .feature-box-inner{
      background: #ffffff  !important;
    }

    .text-feature {
      color: inherit !important;
    }

    &:hover {
      background: #ffffff !important;
      cursor: auto;
    }
  }
}

.feature-box--upcoming {
  height: 100%;

  .feature-box-inner {
    display: flex;
    align-items: center;
    box-shadow: 0 24px 64px rgba(84, 94, 165, 0.1019607843);
    background: #ffffff;
    border-radius: 10px;
    height: 100%;
    padding: 10px;
  }
}

.icon--with-bg--1--feature {
  width: 40px !important;
  height: 40px !important;
  background: linear-gradient(to right top, rgb(31, 89, 177), rgb(40, 93, 163), rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243));
  padding: 3px;
  border-radius: 4px;
  color: #ffffff !important;
  margin-bottom: -7px !important;

}

.headline {
  font-weight: 900;
  font-family: Plus Jakarta Sans !important;
  // background: linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), rgb(0, 188, 167), rgb(0, 191, 114));
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // background-clip: text; /* For Firefox */
  // color: transparent;    /* For Firefox */
  color: rgb(44, 83, 125);
}

.headline-feature {
  font-weight: 600 !important;
  color: rgb(44, 83, 125);
  font-size: 16px !important;
}

.text-feature {
  margin-top: 1px !important;
  max-width: 300px;
  font-size: 13px;
  font-weight: 400 !important;
}

.icon--link {
  display: none;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
