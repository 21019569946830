// Logo Font: Oleo Script Swash Caps
// google-webfonts-helper: https://gwfh.mranftl.com/fonts

/* inter-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('./../_assets/fonts/inter/inter-v13-latin-100.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('./../_assets/fonts/inter/inter-v13-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('./../_assets/fonts/inter/inter-v13-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./../_assets/fonts/inter/inter-v13-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('./../_assets/fonts/inter/inter-v13-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('./../_assets/fonts/inter/inter-v13-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('./../_assets/fonts/inter/inter-v13-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('./../_assets/fonts/inter/inter-v13-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('./../_assets/fonts/inter/inter-v13-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* plus-jakarta-sans-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 200;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 200;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-200italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 300;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 400;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 500;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 600;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 700;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 800;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* plus-jakarta-sans-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 800;
  src: url('./../_assets/fonts/plusjakartasans/plus-jakarta-sans-v8-latin-800italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-100 - latin */
// @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Roboto';
//     font-style: normal;
//     font-weight: 100;
//     src: url('./../_assets/fonts/roboto/roboto-v30-latin-100.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//   }
//   /* roboto-100italic - latin */
//   @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Roboto';
//     font-style: italic;
//     font-weight: 100;
//     src: url('./../_assets/fonts/roboto/roboto-v30-latin-100italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//   }
//   /* roboto-300 - latin */
//   @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Roboto';
//     font-style: normal;
//     font-weight: 300;
//     src: url('./../_assets/fonts/roboto/roboto-v30-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//   }
//   /* roboto-300italic - latin */
//   @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Roboto';
//     font-style: italic;
//     font-weight: 300;
//     src: url('./../_assets/fonts/roboto/roboto-v30-latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//   }
//   /* roboto-regular - latin */
//   @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Roboto';
//     font-style: normal;
//     font-weight: 400;
//     src: url('./../_assets/fonts/roboto/roboto-v30-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//   }
//   /* roboto-italic - latin */
//   @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Roboto';
//     font-style: italic;
//     font-weight: 400;
//     src: url('./../_assets/fonts/roboto/roboto-v30-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//   }
//   /* roboto-500 - latin */
//   @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Roboto';
//     font-style: normal;
//     font-weight: 500;
//     src: url('./../_assets/fonts/roboto/roboto-v30-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//   }
//   /* roboto-500italic - latin */
//   @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Roboto';
//     font-style: italic;
//     font-weight: 500;
//     src: url('./../_assets/fonts/roboto/roboto-v30-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//   }
//   /* roboto-700 - latin */
//   @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Roboto';
//     font-style: normal;
//     font-weight: 700;
//     src: url('./../_assets/fonts/roboto/roboto-v30-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//   }
//   /* roboto-700italic - latin */
//   @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Roboto';
//     font-style: italic;
//     font-weight: 700;
//     src: url('./../_assets/fonts/roboto/roboto-v30-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//   }
//   /* roboto-900 - latin */
//   @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Roboto';
//     font-style: normal;
//     font-weight: 900;
//     src: url('./../_assets/fonts/roboto/roboto-v30-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//   }
//   /* roboto-900italic - latin */
//   @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Roboto';
//     font-style: italic;
//     font-weight: 900;
//     src: url('./../_assets/fonts/roboto/roboto-v30-latin-900italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//   }

// /* source-sans-3-200 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: normal;
//     font-weight: 200;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-200.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-200italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: italic;
//     font-weight: 200;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-200italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-300 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: normal;
//     font-weight: 300;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-300.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-300italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: italic;
//     font-weight: 300;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-300italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-regular - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: normal;
//     font-weight: 400;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-regular.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: italic;
//     font-weight: 400;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-500 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: normal;
//     font-weight: 500;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-500.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-500italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: italic;
//     font-weight: 500;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-500italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-600 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: normal;
//     font-weight: 600;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-600.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-600italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: italic;
//     font-weight: 600;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-600italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-700 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: normal;
//     font-weight: 700;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-700.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-700italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: italic;
//     font-weight: 700;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-700italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-800 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: normal;
//     font-weight: 800;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-800.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-800italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: italic;
//     font-weight: 800;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-800italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-900 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: normal;
//     font-weight: 900;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-900.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* source-sans-3-900italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Source Sans 3';
//     font-style: italic;
//     font-weight: 900;
//     src: url('./../_assets/fonts/sourcesans3/source-sans-3-v10-latin-900italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-100 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: normal;
//     font-weight: 100;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-100.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-100italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: italic;
//     font-weight: 100;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-100italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-200 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: normal;
//     font-weight: 200;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-200.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-200italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: italic;
//     font-weight: 200;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-200italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-300 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: normal;
//     font-weight: 300;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-300.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-300italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: italic;
//     font-weight: 300;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-300italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-regular - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: normal;
//     font-weight: 400;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-regular.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: italic;
//     font-weight: 400;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-500 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: normal;
//     font-weight: 500;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-500.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-500italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: italic;
//     font-weight: 500;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-500italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-600 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: normal;
//     font-weight: 600;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-600.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-600italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: italic;
//     font-weight: 600;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-600italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-700 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: normal;
//     font-weight: 700;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-700.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-700italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: italic;
//     font-weight: 700;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-700italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-800 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: normal;
//     font-weight: 800;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-800.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-800italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: italic;
//     font-weight: 800;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-800italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-900 - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: normal;
//     font-weight: 900;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-900.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// /* raleway-900italic - latin */
// @font-face {
//     font-display: swap;
//     /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Raleway';
//     font-style: italic;
//     font-weight: 900;
//     src: url('./../_assets/fonts/raleway/raleway-v29-latin-900italic.woff2') format('woff2');
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }