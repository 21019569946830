.dzu-dropzone {
  overflow: hidden;
  border-style: dashed;
  border-color: #f0f3ff;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  // height: 216px;
  // overflow-y: scroll;
  align-content: flex-start
}

.dzu-dropzone.single {
  height: 216px;
}

.dzu-dropzone.multiple {
  height: auto;
}

.dzu-dropzone.single .dzu-submitButtonContainer {
  display: none;
}

.dzu-previewFileNameError {
  color: red;
  
  + span {
    display: none;
  }
}

.dzu-previewContainer {
  border-bottom: none;
  height: 100%;
  justify-content: center;
  padding: 0;
  background: #f0f3ff;
  border-radius: 60px;
  margin: 2px;
  height: auto;
}

.dzu-previewImage {
  margin-right: 5px;
}

.dzu-previewStatusContainer {
  progress::-moz-progress-bar { background-color: #23c723; }
  progress::-webkit-progress-value { background-color: #23c723; }
  progress { background-color: #23c723; }
  progress {
    border: 0;
    height: 15px;
    border-radius: 20px;
  }
  progress::-webkit-progress-bar {
    border: 0;
    height: 15px;
    border-radius: 20px;
  }
  progress::-webkit-progress-value {
    border: 0;
    height: 15px;
    border-radius: 20px;
  }
  progress::-moz-progress-bar {
    border: 0;
    height: 15px;
    border-radius: 20px;
  }
}


.dropzone-preview-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f0f3ff;
  width: 100%;
  padding: 15px;

  img {
    // width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 100px;
    margin: 0 auto;
  }

  @media(max-width: 1279px) {
    margin-bottom: 10px;
  }

  @media(min-width: 1280px) {
    margin-right: 10px;
  }
}

.dzu-inputLabel {
  position: relative;
  height: 97%;
  width: 100% !important;
  padding: 50px 10px;
}

.dropzone-upload-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100% !important;
    height: 216px;

    .dropzone-button {
      display: flex;
      padding: 10px 16px;
      border: 1px solid;
      border-radius: 3px;
      margin-bottom: 10px;
    }
}

.dzu-inputLabelWithFiles {
  display: none;
}

.dzu-dropzoneDisabled {
  opacity: 1 !important;
}

.dzu-previewContainer {
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dzu-previewButton {
  position: absolute;
  right: 20px;
  top: 24px;
}

.dzu-previewImage {
  margin-right: 0;
  margin-bottom: 5px;
}

.dzu-previewFileName {
  width: 140px;
  word-break: break-all;
}

.dzu-previewButton {
  display: none;
}

.alert-dropzone {

  .MuiAlert-icon {
    align-self: flex-start;
  }

  .MuiAlert-message {
    flex-direction: column;
    align-items: flex-start;
  
    ul {
      text-align: left;
      margin: 0;
      padding-left: 17px;
    }
  }
}

