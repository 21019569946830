.date-range-toggle {
  
  .MuiToggleButton-root {
    line-height: 1 !important;
    text-transform: none !important;
  }
}

.date {
  background: #ffffff;
  border: 1px solid rgb(237, 239, 255);

  @media(max-width: 959px) {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

.date-text {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media(max-width: 959px) {
    flex-direction: column;
    align-items: center;
    text-align: center !important;
    width: 100%;
  }
}

.date-range-hint {
  background: #ffffff;
  color: rgb(44, 83, 125);
  width: 100%;

  @media(min-width: 960px) {
    margin-left: 5px;
    width: auto;
  }

  @media(max-width: 1196px) {
    display: none;
  }
}

.date-type {

  @media(max-width: 1196px) {
    display: none;
  }
}

.daterange-dropdowns {

  @media(max-width: 959px) {
    flex-direction: column;
  }
}
