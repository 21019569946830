document {
  // color: rgba(0, 0, 0, 0.87);
  // margin: 0;
  // font-size: 0.875rem;
  // font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // font-weight: 400;
  // line-height: 1.43;
  // letter-spacing: 0.01071em;
  // margin: 0 auto;
}

page {
  display: flex;
  // width: 1000px;
  min-height: 1410px;
  padding: 75px 75px !important;
}

view {
  flex-grow: 1;
  zoom: 160%;
  margin: 0;
  padding: 0;
}

text {
  line-height: 1.2;
  // font-family: "Inter" !important
}

image {
  display: none !important;
}

.table {
  display: table;
  zoom: 100%;
}

.invoice-paper {
  width: 50%;

  @media(max-width: 960px) {
    // width: 200px;
    // position: fixed; /* Sit on top of the page content */
    // z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    // cursor: pointer; /* Add a pointer on hover */
    display: none;
  }
}

.invoice-pdf-viewer {

  @media(max-width: 960px) {
    display: none;
  }
}

.invoice-preview-dialog {

  .MuiDialog-paperFullWidth {
    width: 50%;

    @media(max-width: 1480px) {
      width: 100%;;
    }
  }
}

.editor-wrapper {
  padding: 1rem;
  // border: 2px solid rgb(240, 243, 255);
  border-radius: 8px;
}

.editor {
  background-color:#ffffff;
  padding: 1rem;
  border-radius: 8px; /* Ensure the inner editor matches the wrapper's radius */
  // border: 1px solid #ccc;
}

.rdw-editor-toolbar {
  border: 0px;
}

.invoice-footer {
  margin-left: -25px;
  font-size: 12px;

  &:last-of-type {
    padding-bottom: 50px
  }
}

.email-pdf-preview {
  
  &:hover {
    cursor: pointer;
  }
}

.add-recipients {

  &:hover {
    cursor: pointer;
  }
}

.button-send-dialog-send {
  background: #1a7bc9 !important;

  svg {
    color: #8eeb8e;
  }

  &:hover {
    background: #2690e5 !important;


  }
}

.button-send-dialog-schedule {
  background: #ffffff !important;
  color: rgb(44, 83, 125) !important;

  svg {
    border-radius: 60px;
  }

  &:hover {
    background: rgb(255, 230, 195) !important;

    svg {
      color: #ffffff !important;
      background: rgb(255, 183, 77);
    }
  }
}

.dialog-actions-topbar {

  @media(max-width: 1399px) {
    display: none;
  }
}

.dialog-actions-bottombar {
  
  @media(min-width: 1400px) {
    display: none;
  }
}