.invoice-pdf-preview {

  // .MuiPaper-root {
  //   width: 100% !important;
  // }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
}