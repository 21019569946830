.container--promo {
    padding: 20px 25px 30px 25px !important;
    color: #ffffff;
    font-weight: 700;

    h3 {
        padding-bottom: 5px;
    }

    p {
        line-height: 1.2
    }

    .list {
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .list-item {
        display: flex;
        align-items: center;
        padding-top: 2.5px;
        padding-bottom: 2.5px;

        p {
            line-height: 1;
        }
    }

    .list-item-icon {
        padding: 2px;
        margin-right: 5px;
    }

    button {
        font-weight: 700;

        svg {
            margin-left: 5px;
        }

        &:hover {
            filter: brightness(120%);
        }
    }
}