.icon-wrapper--lg {
    display: flex;
    padding: 8px;
    border-radius: 30px;
    margin-bottom: 5px;

    svg {
        color: #ffffff;
    }
}

.icon--with-bg--1 {
    padding: 3px;
    border-radius: 4px;
    background-image: linear-gradient(to right top, rgb(31 89 177), rgb(40 93 163), rgb(38 108 189), rgb(36, 115, 192), rgb(33, 150, 243));
    color: #ffffff !important;
}

.MuiAlert-icon {
    align-items: center;
}

.MuiAlert-message {
    display: flex;
    align-items: center;
}