.MuiAppBar-colorDefault {
  background-color: white;
}

.MuiTabs-root {
  max-width: 1800px;
  padding-left: 389px;
  @media(max-width: 959px) {
    padding-left: 0;
  }
}

.tab-bar {
  box-shadow: none !important;
  background: rgb(237, 239, 255) !important;
  margin-top: 50px;

  @media(max-width: 959px) {
    margin-top: 45px;
  }
}

.MuiTab-wrapper {
  text-transform: none;
}

.settings-header {

  @media(max-width: 959px) {
    display: none;
  }
}

.settings-container {
  margin-top: 130px;

  @media(max-width: 959px) {
    margin-top: 50px;
  }
}

