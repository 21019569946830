.search {
  // padding-left: 40px;
  // padding-right: 40px;

  // @media(max-width: 959px) {
  //   padding-left: 4px;
  //   padding-right: 4px;
  // }

  border: 1px solid #edefff;
  border-radius: 4px;
}

.search-field .MuiFilledInput-root {
  border-radius: 6px !important;
  border: 1px solid rgb(237, 239, 255) !important;
}

.search-field {

  #searchInput {
    font-size: 12px !important;
  }
  #searchInput::placeholder {
    font-size: 12px !important;
  }
}

