.editor {
  padding: 0 10px !important;
}

.editor-wrapper {
  width: 100%;
  padding: 0 !important;
}

.rdw-editor-main {
  height: auto !important;
}

.editor-invoice-details {

  .public-DraftStyleDefault-block,
  .public-DraftStyleDefault-ol,
  .public-DraftStyleDefault-ul {
    margin: 0 !important;
  }

  .public-DraftStyleDefault-ul .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
    margin-left: 16px !important;
  }

  .public-DraftStyleDefault-ol .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
    margin-left: 24px !important;
  }
}