.letter-image {
    position: relative;
    width: 200px;
    height: 200px;
    margin-bottom: 30px;
    // -webkit-transform: translate(-50%, -50%);
    // -moz-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
}

.animated-mail {
    position: absolute;
    height: 150px;
    width: 200px;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    transition: .4s;

    .body {
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 100px 200px;
        border-color: transparent transparent #0277BD transparent;
        z-index: 2;
    }

    .top-fold {
        position: absolute;
        top: 50px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 100px 0 100px;
        -webkit-transform-origin: 50% 0%;
        -webkit-transition: transform .4s .4s, z-index .2s .4s;
        -moz-transform-origin: 50% 0%;
        -moz-transition: transform .4s .4s, z-index .2s .4s;
        transform-origin: 50% 0%;
        transition: transform .4s .4s, z-index .2s .4s;
        border-color: #0277BD transparent transparent transparent;
        z-index: 2;
    }

    .back-fold {
        position: absolute;
        bottom: 0;
        width: 200px;
        height: 100px;
        background: #0277BD;
        z-index: 0;
    }

    .left-fold {
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 0 50px 100px;
        border-color: transparent transparent transparent #0277BD;
        z-index: 2;
    }

    .letter {
        border: 1px solid #e9e9e9;
        left: 20px;
        bottom: 0px;
        position: absolute;
        width: 160px;
        height: 60px;
        background: white;
        z-index: 1;
        overflow: hidden;
        -webkit-transition: .4s .2s;
        -moz-transition: .4s .2s;
        transition: .4s .2s;

        .letter-border {
            height: 10px;
            width: 100%;
            background: repeating-linear-gradient(-45deg,
            #0277BD,
            #0277BD 8px,
                    transparent 8px,
                    transparent 18px);
        }

        .letter-title {
            margin-top: 10px;
            margin-left: 5px;
            height: 10px;
            width: 40%;
            background: #0277BD;
        }

        .letter-context {
            margin-top: 10px;
            margin-left: 5px;
            height: 10px;
            width: 20%;
            background: #0277BD;
        }

        .letter-stamp {
            margin-top: 30px;
            margin-left: 120px;
            border-radius: 100%;
            height: 30px;
            width: 30px;
            background: #0277BD;
            opacity: 0.3;
        }
    }
}

.shadow {
    position: absolute;
    top: 200px;
    left: 50%;
    width: 400px;
    height: 30px;
    transition: .4s;
    transform: translateX(-50%);
    -webkit-transition: .4s;
    -webkit-transform: translateX(-50%);
    -moz-transition: .4s;
    -moz-transform: translateX(-50%);

    border-radius: 100%;
    background: radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0));
}

.letter-image.open {
    .animated-mail {
        transform: translateY(50px);
        -webkit-transform: translateY(50px);
        -moz-transform: translateY(50px);
    }

    .animated-mail .top-fold {
        transition: transform .4s, z-index .2s;
        transform: rotateX(180deg);
        -webkit-transition: transform .4s, z-index .2s;
        -webkit-transform: rotateX(180deg);
        -moz-transition: transform .4s, z-index .2s;
        -moz-transform: rotateX(180deg);
        z-index: 0;
    }

    .animated-mail .letter {
        height: 180px;
    }

    .shadow {
        width: 250px;
    }
}
