.h-minHeight--100 {
    min-height: 100%;
    min-height: 100vh;
}

.h-gap--5 {
    gap: 5px;
}

.h-gap--10 {
    gap: 10px;
}

.h-gap--15 {
    gap: 15px;
}

.h-gap--20 {
    gap: 20px;
}

.h-gap--25 {
    gap: 25px;
}

.h-gap--30 {
    gap: 30px;
}

.h-gap--35 {
    gap: 35px;
}

.h-gap--40 {
    gap: 40px;
}

.h-gap--45 {
    gap: 45px;
}

.h-gap--50 {
    gap: 50px;

    @media(max-width: 700px) {
        padding: 50px 15px !important;
    }
}

.h-bg--white {
    background: #ffffff;
}

.h-padding--0-16 {
    padding: 0px 16px;

    @media(min-width: $lg) {
        padding: 0px 50px;
    }
}

.h-padding--5-16 {
    padding: 5px 16px;

    @media(min-width: $lg) {
        padding: 5px 16px;
    }
}

.h-padding--25-16 {
    padding: 25px 16px;

    @media(min-width: $lg) {
        padding: 25px 16px;
    }
}

.h-padding--50-16 {
    padding: 25px 16px;

    @media(min-width: $lg) {
        padding: 50px 50px;
    }
}





.h-padding--5-5 {
    padding: 5px 5px;

    @media(min-width: $lg) {
        padding: 5px 5px;
    }
}







.h-borderRadius--10 {
    border-radius: 10px !important;
}

.h-borderRadiusTopRight--10 {
    border-top-right-radius: 10px !important;
}


