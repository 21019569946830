.invoice-edit {
  // width: 50%;

  @media(max-width: 960px) {
    width: 100%;
  }
}

.snackbar-mailInvoice {
  &.MuiSnackbar-root,
  .MuiSnackbarContent-root {
      width: 100%;
      border-radius: 0;
      bottom: 0;
      justify-content: center;
      background: #ffffff
  }
}

.mobile-hide {
  @media(max-width: 560px) {
    display: none;
  }
}

.MuiButton-startIcon--mobile {

  @media(max-width: 560px) {
    .MuiButton-startIcon {
      margin-right: -5px;
    }
  
    .MuiButton-root {
      min-width: auto
    }
  }
}

.MuiAccordionSummary-root.Mui-focused, .MuiAccordionSummary-root.Mui-focusVisible {
  // background-color: #f0f3ff !important;
  background-color: #ffffff !important;
}


.MuiBottomNavigationAction-root {
  max-width: 100% !important;
}

.bottom-navigation-element {
    
    // color: inherit !important;

    // &:hover {
    //   // background: white !important;
    //   color: #2196f3 !important;
    // }



    &.primary {
      background: #2196f3 !important;
      color: white !important;
      font-weight: 600;

      &:hover {
        background: #1a7bc9 !important;
        color: white !important;
      }
    }

    &.secondary {
      background: #E312CA !important;
      color: white !important;
      font-weight: 600;

      &:hover {
        background: #a90795 !important;
        color: white !important;
      }
    }


}

.MuiAccordion-root {
  // padding-top: 15px !important;
  // padding-bottom: 15px !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 64px rgb(84 94 165 / 17%) !important;
  margin-bottom: 10px !important;
}

.accordion {
  // margin-bottom: 10px !important;
  border: none !important;
  // border-bottom: 2px solid #d8def1 !important;

  &:last-of-type {
    border-bottom: 1px solid transparent !important;
  }


}

.input-white {
  input {
      background: #ffffff;
  }
}

// Change color in general of TextField and Select outline
.MuiOutlinedInput-notchedOutline {
  border-width: 2px !important;
  border-color: #f0f3ff  !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2196f3  !important;
}

.MuiAccordion-root {
  
  &.Mui-expanded {
    border: 2px solid #a7a9b9 !important;
  }
}

.MuiAccordionSummary-root {
  border-radius: 10px !important;

  &:hover {
    cursor: pointer;
  }

  &.Mui-expanded {
    background: #e4e6f7 !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    // &:hover {
    //   background: #e4e6f7 !important;
    // }
  }

  .edit-icon,
  .drag-icon {
    background: rgb(156, 173, 201) !important;
    color: #fff !important;
    border-radius: 60px;
    padding: 2px;
  }

  &:hover {
    background: #e4e6f7 !important;
    
    .edit-icon,
    .drag-icon {
      background: #798dad !important;
      color: #fff !important;
      border-radius: 60px;
    }
  }
}

.edit-icon {
   
  @media(max-width: 959px) {
    display: none !important;
  }
}

.drag-icon {
  margin-right: 10px;

  @media(max-width: 959px) {
    display: none !important;
  }
}

.MuiAccordionDetails-root {
padding: 25px 8px 15px 15px !important;
}

.sent-info {

  .MuiAlert-standardInfo .MuiAlert-icon {
    display: none;
  }

  .MuiAlert-message {
    flex-direction: column;
  }
}


.alert {

  .MuiAlert-icon {
    display: block;
  }
}

.MuiBottomNavigationAction-root {
  max-width: 200px !important;
  box-shadow: rgba(84, 94, 165, 0.1) 0px 3px 3px -2px, rgba(84, 94, 165, 0.1) 0px 3px 4px 0px, rgba(84, 94, 165, 0.1) 0px 1px 8px 0px;
}

.bottom-navigation-element.primary.invoice {
  font-weight: bold;
}

.bottom-navigation-element.secondary.invoice {
  font-weight: bold;
  border-top-right-radius: 0px;
  border-top-right-radius: 10px;
}

.MuiDialog-paperFullScreen {
  overflow-x: hidden;
}

.badge-input--invoicenr {
  outline: 0 !important;
  max-width: 100%;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.54);
  border-radius: 3px;

  // &:hover {
  //   background: #d2d7f3 !important;
  // }

  &:active,
  &:focus,
  &:focus-visible {
      background: #ffffff !important;
      color: initial;
  }

  &:focus::placeholder {
      color: transparent !important;
    }
}

.badge-input--date {
  border: 0 !important;
  outline: 0 !important;
  max-width: 100%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);

  // &:hover {
  //   background: #d2d7f3 !important;
  //   cursor: pointer;
  // }

  &:active,
  &:focus,
  &:focus-visible {
      background: #ffffff !important;
      color: initial;
  }
}

.icon--invoicenr-dots {
  background: rgb(210 215 243 / 20%) !important;

  &:hover {
    background: #d2d7f3 !important;
  }

  &:active {
      background: #ffffff !important;
  }
}

.invoiceNumberItem {

  @media(max-width: 1279px) {
    width: 100%;
    margin: 0 15px 15px 15px !important;
  }

  &:first-of-type {

    @media(max-width: 1279px) {
      width: 100%;
      margin-top: 15px !important;
    }
  }
}

.invoiceNumberHeadline {

  @media(max-width: 1279px) {
    display: block;
  }
}

.invoiceNumberItem--v2 {
  border: 2px solid rgb(240, 243, 255);

  @media(max-width: 1279px) {
    width: 100%;
    margin-bottom: 5px !important;
  }

  ::placeholder {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.2);
  }
}

.invoiceNumberItem--v3 {
  width: 100%;
  margin-bottom: 5px !important;

  ::placeholder {
    font-size: 12px;
    // color: rgba(0, 0, 0, 0.2);
  }
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: rgb(44, 83, 125);
  border-radius: 50%;
  animation: dot-animation 1.2s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-animation {
  0%, 80%, 100% {
    transform: scale(0.8);
    opacity: 0.3;
  }
  40% {
    transform: scale(1.1);
    opacity: 1;
  }
}


.animate-change {
  animation: fade-in-out 1s ease-in-out;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.app-bar-button {

  .MuiButton-startIcon {

    @media(max-width: 900px) {
      margin-right: 0;
    }
  }
}

.app-bar-button-label {

  @media(max-width: 500px) {
    display: none;
  }
}

.invoice-dialog-close-appbar {

  @media(max-width: 900px) {
    display: none !important;
  }
}

.invoice-dialog-status-appbar {

  @media(max-width: 900px) {
    display: none !important;
  }
}

.appbar-left {
  flex: 1;

  @media(max-width: 900px) {
    flex: 0.5;
  }
}

.appbar-right {
  flex: 1;

  @media(max-width: 900px) {
    flex: 2;
  }
}

.invoice-dialog-headline-appbar {

  @media(max-width: 900px) {
    display: none;
  }
}

.app-bar-button--send {
  margin-right: 10px !important;

  @media(max-width: 900px) {
    margin-right: -10px !important;
  }
}

.appbar-schedule-additional-text {

  @media(max-width: 1000px) {
    display: none;
  }
}

.break-schedule-appbar-text {
  display: none;

  @media(max-width: 1190px) {
    display: block;
  }
}

.schedule-appbar-button {
  background: rgba(255,255,255,0.6);
  padding: 5px 10px;
  font-weight: 500;
  margin-left: 15px;
  border-radius: 60px;
  margin-right: -8px;

  &:hover { 
    cursor: pointer;
    background: #ffffff;
  }
}

.rdw-editor-toolbar {
  padding-top: 10px;
  padding-bottom: 5px;
  background: rgb(240, 243, 255);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.rdw-option-wrapper {
  border-radius: 6px !important;
}

.DraftEditor-root {
  padding-top: 18px;
  padding-bottom: 18px;
}

.rdw-editor-toolbar {
  margin-bottom: 0;
}

.rdw-editor-main {

  &:hover {
    cursor: text;
  }
}


.suggestion-item {
  display: flex;
  justify-content: flex-start; /* Align text and button on opposite ends */
  align-items: center;
  padding: 12px 5px;
  padding-left: 0;
  transition: background-color 0.2s ease;
  position: relative;
  width: 100%; /* Ensure the item spans the full width */
}

// .suggestion-item:hover {
//   background-color: #f0f0f0; /* Change background color on hover */
// }

.add-button-icon {
  color: rgb(44, 83, 125);
  opacity: 0.2;
  margin-right: 5px;
}

.add-button-icon:hover {
  color: #1a7bc9; /* Darker blue on hover */
}

.suggestion-item:hover .add-button-icon {
  opacity: 1;
}

.recurring-headline-long {

  @media(max-width: 1200px) {
    font-size: 16px,
  }
}