%bg--1 {
    background: #f7fafd
}

.bg--1 {
    @extend %bg--1;
}

// https://mycolor.space/gradient?ori=to+right+top&hex=%23051937&hex2=%232196F3&sub=1
%bg--2 {
    background: linear-gradient(to right top, #051937, #123461, #1d528f, #2473c0, #2196f3);
}

.bg--2 {
    @extend %bg--2;
}

// https://mycolor.space/gradient?ori=to+right+top&hex=%23051937&hex2=%23E312CA&sub=1
%bg--3 {
    background: linear-gradient(to right top, #051937, #222a67, #573592, #9934b5, #e312ca);
}

.bg--3 {
    @extend %bg--3;
}

%bg--4 {
    background-image: linear-gradient(to right bottom, #051937, #004d7a, #008793, #00bf72, #a8eb12);
    // background-image: linear-gradient(to right top, #051937, #004468, #00727d, #009e6e, #8bc34a);
}

.bg--4 {
    @extend %bg--4;
}

%bg--5 {
    background-image: linear-gradient(to right bottom, #725e00, #937a00, #b69800, #dab700, #ffd700);
}

.bg--5 {
    @extend %bg--5;
}

%bg--6 {
    background-image: radial-gradient(circle at 60.52% 67.2%, #264697, transparent 41%), radial-gradient(circle at 56.14% 12.23%, #232b69, transparent 85%), radial-gradient(circle at 6.01% 1.34%, #d989b9, transparent 25%), radial-gradient(circle at 75.71% 4.03%, #0a0d66, transparent 34%), radial-gradient(circle at 99.31% 70.7%, #2fd2eb, transparent 75%), radial-gradient(circle at 76.91% 59.14%, #0a0d66, transparent 71%), radial-gradient(circle at 71.07% 83.33%, #0a0d66, transparent 71%), radial-gradient(circle at 15.11% 70.43%, #1556a1, transparent 64%), radial-gradient(circle at 65.49% 37.9%, #3058a5, transparent 100%), radial-gradient(circle at 99.4% 93.55%, #12f7ff, transparent 100%), radial-gradient(circle at 50% 50%, #8f62aa, #8f62aa 100%)
}

.bg--6 {
    @extend %bg--6;
}

%bg--7 {
    background: linear-gradient(90deg, rgba(226,229,255,1) 0%, rgba(255,255,255,1) 100%);
}

.bg--7 {
    @extend %bg--7;
}

%bg--basic {
    background: darkgrey !important;
}

.bg--basic {
    @extend %bg--basic;
}