.iconbutton--add {
  color: rgb(44, 83, 125) !important;

  &:hover {
    color: #1a7bc9 !important;
  }
}

.icon--with-bg--1 {
  background: linear-gradient(to right top, rgb(31, 89, 177), rgb(40, 93, 163), rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243));
}

.active {

  .icon--with-bg--1 {
    background: linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), rgb(0, 188, 167), rgb(0, 191, 114));
  }
}

.MuiListItem-button {

  &:hover {
    .icon--with-bg--1 {
      background: linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), rgb(0, 188, 167), rgb(0, 191, 114));
    }
  }
}

.icon-button-nav-element {
  color: #ffffff !important;

  &:hover {
    color: #2196f3 !important;
  }
}

.version {
  // background: rgba(0,0,0,0.2);
  background: linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), rgb(0, 188, 167), rgb(0, 191, 114)); 

  &:hover {
    background: #E312CA;
  }
}

.MuiListItem-root .MuiTypography-body1 {
  color: rgb(44, 83, 125);
  font-weight: 500 !important;
}

.app-drawer-navlink {

  .iconbutton--add {
    display: none;
  }

  &:hover {

    .iconbutton--add {
      display: block;
    } 
  }

  @media(max-width: 959px) {

    .iconbutton--add {
      display: block;
    }
  }
}