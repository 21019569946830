@keyframes uploading-arrow {
  0% {
    margin-bottom: -40%;
	 opacity: 0;
  }
	20% {
		opacity: 1
	}
	50%{
		opacity: 1;
	}
	75% {
		
	  opacity: 0;
	}
  100% {
    margin-bottom: 10%;
  }
}

.upload-btn-container {
  position: relative;
	overflow: hidden;
	width: 32px;
	height: 32px;
	display: inline-block;
  zoom: 2;
  background: #e6edf3;
  border-radius: 60px;
   
	
   svg {
      position: absolute;
		
		&.upload-box {
			bottom: -7px;
		}
      
		&.upload-arrow {
			left: 8px;
			position: absolute;
			opacity: 0;
			bottom: 20%;
			animation: uploading-arrow 1.5s ease-out infinite;
		}
   }
}